import React, { useState, useEffect } from 'react'
import { List } from 'antd'

import Main from '../components/Main'
import {
  LOGS,
  requestToServer
} from '../lib/helper'

export default  () => {

  const [logs, setLogs] = useState([])

  useEffect(() => {
    requestToServer({}, LOGS).then( r => setLogs(r.logs) )
  }, [])

  let columns = [

  ]

  return(
    <Main
      pageHeader={{ title: 'Logs'  , back: true }}
    >
    { logs.map( (item, index) => {
      return ( <div key={'list_' + index}>
        <div><strong>{item.platformName}</strong> - {item.createdAt}</div>

    {item.text.split("\n").map(function(item) {
              return (
                <>
                {item}
                <br/>
                </>
              )
            })}    


      </div>)
    })} 

      
    </Main>
  )
}